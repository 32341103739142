import { useQuery, UseQueryResult } from "react-query";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type Zona = {
  cod: number;
  name: string;
  microregions: MicroRegions[];
};

export type MicroRegions = {
  id: string;
  cod: number;
  name: string;
  areaCod: number;
};

export type ZonaFilters = {};

type GetZonasResponse = {
  zonas: Zona[];
  totalCount: number;
};

interface ZonaApiResponse extends ResponsePanelApiDefault {
  contents: Zona[];
}

export async function getZonas(
  page: number,
  filter?: ZonaFilters,
  registersPerPag?: number
): Promise<GetZonasResponse> {
  const { data } = await api.get<ZonaApiResponse>("/microregion/zonas", {
    params: { pageRequest: page - 1, limitRequest: registersPerPag, ...filter },
  });

  return {
    totalCount: data.total,
    zonas: data.contents,
  };
}

export function useZonas(
  page: number,
  filter?: ZonaFilters,
  registersPerPag?: number
): UseQueryResult<GetZonasResponse> {
  return useQuery(
    ["zonas", page, filter],
    () => getZonas(page, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}
