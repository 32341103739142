import React, { ReactNode } from "react";

import { Container, Group, Heading } from "./styles";

interface BoxProps {
  style?: React.CSSProperties;
  children?: ReactNode;
}

export const Box: React.FC<BoxProps> = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

interface BoxHeadingProps {
  children?: ReactNode;
  style?: React.CSSProperties;
}
export const BoxHeading: React.FC<BoxHeadingProps> = ({
  children,
  ...rest
}) => {
  return <Heading {...rest}>{children}</Heading>;
};

interface BoxGroupProps {
  children: ReactNode;
  direction?: "vertical" | "horizontal";
}
export const BoxGroup: React.FC<BoxGroupProps> = ({
  children,
  direction = "horizontal",
}) => {
  return (
    <Group
      style={{ flexDirection: direction === "horizontal" ? "row" : "column" }}
    >
      {children}
    </Group>
  );
};
