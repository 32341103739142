import React from "react";
import { IPermissionUser } from "../../@types";
import { useSsoCatalog } from "../../hook/useSsoCatalog";
import * as clientStorage from "../../service/localStorage/client";
import * as ProviderStorage from "../../service/localStorage/provider";
import * as SellerStorage from "../../service/localStorage/seller";
import * as UserStorage from "../../service/localStorage/user";
import { HomeAnalytic } from "./HomeAnalytic";
import { HomeBanners } from "./HomeBanners";
import { HomeBoxes } from "./HomeBoxes";
import { Container } from "./styles";

interface Client {
  id?: string;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
}

interface User {
  id: string;
  name: string;
  lastname: string;
  email: string;
  token: string;
  permission: string;
}

interface HeaderProps {
  admin?: boolean;
  client?: boolean;
  seller?: boolean;
  provider?: boolean;
  is_manager?: boolean;
  is_supervisor?: boolean;

  permission?: IPermissionUser;
}

const Home: React.FC<HeaderProps> = ({
  admin,
  client,
  seller,
  provider,
  permission,
}) => {
  const clientData = clientStorage.getUser() as Client;
  const UserData = UserStorage.getUser() as User;
  const SellerData = SellerStorage.getUser() as SellerStorage.SellerProps;
  const ProviderData = ProviderStorage.getUser() as ProviderStorage.Provider;

  const { accessSsoCatalog } = useSsoCatalog({
    entity: client ? "client" : seller ? "seller" : "user",
    email: client ? clientData.email : UserData?.email,
    sellerCod: SellerData?.cod?.toString(),
  });

  return (
    <>
      <Container>
        <h2>
          Olá,
          {admin ? ` ${UserData.name} ${UserData.lastname}` : null}
          {client ? ` ${clientData.trade_name}` : null}
          {seller ? ` ${SellerData.fullName}` : null}
          {provider ? ` ${ProviderData.fullname}` : null}
        </h2>

        <HomeBanners
          admin={admin}
          client={client}
          seller={seller}
          provider={provider}
          accessSsoCatalog={accessSsoCatalog}
        />

        <HomeBoxes
          admin={admin}
          client={client}
          provider={provider}
          seller={seller}
          permission={permission}
          providerIsShippingCompany={ProviderData?.isShippingCompany}
          accessSsoCatalog={accessSsoCatalog}
        />

        {(admin || client || seller) && (
          <HomeAnalytic
            clientId={clientData?.id ? Number(clientData.id) : undefined}
            userPermission={UserData?.permission}
            userType={
              admin
                ? "admin"
                : client
                ? "client"
                : seller && (SellerData.is_manager || SellerData.is_supervisor)
                ? "sellerManager"
                : "seller"
            }
          />
        )}
      </Container>
    </>
  );
};

export default Home;
