import React from "react";

import { Container } from "./styles";

interface Props {
  type?: "button" | "submit" | "reset" | undefined;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export const ButtonSearch: React.FC<Props> = ({
  children,
  type = "submit",
  style,
  onClick,
  disabled,
}) => {
  return (
    <Container disabled={disabled} onClick={onClick} type={type} style={style}>
      {children}
    </Container>
  );
};
