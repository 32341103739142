import React, { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import ModalConfirm from "../../../components/ModalLib";
import api from "../../../service/api";
import * as clientLocalStorage from "../../../service/localStorage/client";

import filesize from "filesize";
import Dropzone from "../../../components/Upload/Dropzone";
import generateHash from "../../../utils/generateHash";

import Loading from "../../../components/loadings/Loading";

import { useFormik } from "formik";
import * as Yup from "yup";

import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle, FiSearch } from "react-icons/fi";
import { IoMdTrash } from "react-icons/io";
import {
  ButtunSubmit,
  Container,
  ContainerCheck,
  ContainerDropZone,
  ContainerForm,
  ContainerList,
  ContainerPreviews,
  Error,
  Filter,
  FilterCheck,
  FilterSelects,
  FooterContainer,
  Form,
  MainFilter,
  ModalBody,
  TableContainer,
} from "../styles";

import useOutsideAlerter from "../../../hook/outsideAlerter";

import { AxiosError } from "axios";
import moment from "moment";
import { BsFileEarmarkCheck } from "react-icons/bs";
import iconPdf from "../../../assets/icon_pdf.png";
import iconXml from "../../../assets/icon_xml.png";
import Modal from "../../../components/Modal";
import { FRONT_LINK } from "../../../global/variables";
import { Order } from "../../../hook/queries/useOrders";
import { ScreenLoading } from "../../analytic/styles";
import { PopUpConfirmation } from "../../createAndDetail/styles";

interface IHeaderProps {
  admin: boolean;
  provider: boolean;
}

interface IClient {
  id?: string;
  cod?: number;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
  phone?: string;
}

interface IFile {
  id: string;
  name: string;
  size: Number;
  url?: string;
  key?: string;
  preview_url?: string;
  mimetype?: string;
}

interface IDevolution {
  client?: {
    cod: number | undefined;
    company_name: string | undefined;
  };
  visible: boolean | undefined;
  refusal: boolean | undefined;

  requesterName: string;
  phone: string;
  email: string;

  //Dados da solicitação
  numberInvoice: string;
  situationRequest: string | undefined;
  products: string;

  requestType: string;

  //devolução
  reason: string;
  dateInvoice: string;
  brand: string;

  //Dados bancários
  // bank: string;
  // agency: string;
  // account: string;
  // typeAccountBank: string;
}

interface IBrand {
  cod: number;
  name: string;
}

interface IClietsApi {
  clients: IClient[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IFilter {
  cod?: string;
  company_name?: string;
  cnpj?: string;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

const CreateDecolucao: React.FC<IHeaderProps> = ({ admin, provider }) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const outSideRefFilter = useRef(null);

  const clietStorage = clientLocalStorage.getUser() as IClient;
  const [selectFile, setSelectFile] = useState<File>();
  const [files, setFiles] = useState<File[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);

  const [loading, setLoading] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);

  const [devolutionExistsToInvoice, setDevolutionExistsToInvoice] = useState<
    number[]
  >([]);
  const [isConfirmNumberInvoice, setIsConfirmNumberInvoice] = useState(false);
  const [isValidNumberInvoice, setIsValidNumberInvoice] = useState(false);
  const [order, setOrder] = useState<Order>({} as Order);

  const [modalVisible, setModalVisible] = useState(false);

  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [requestPagination, setRequestPagination] =
    useState<IRequestPagination>({ limitRequest: 10 } as IRequestPagination);

  const [clints, setClients] = useState<IClient[]>([]);
  const [filters, setFilters] = useState<IFilter>({});
  const [filterView, setFilterView] = useState(false);

  useEffect(() => {
    if (selectFile) setFiles([...files, selectFile]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFile]);

  useEffect(() => {
    (async () => {
      const brandDB = await api.get<IBrand[]>("/populate/brand", {
        params: { active: 1 },
      });
      setBrands(brandDB.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (admin) {
        try {
          const clients = await api.get<IClietsApi>("/clientPJ", {
            params: { ...filters, ...requestPagination },
          });
          const { limit, page, pages, total } = clients.data;
          setClients(clients.data.clients);
          setPagination({ limit, page, pages, total });
        } catch (error) {
          return addToast(
            "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, requestPagination]);

  async function hadleDeleteFile(name: string) {
    files.forEach((element: File, index) => {
      if (element.name === name) {
        files.splice(index, 1);
      }
    });

    setFiles([]);

    setTimeout(() => {
      setFiles(files);
    }, 1);
  }

  async function hadleSubmitClient(devolution: IDevolution) {
    setLoading(true);
    const formData = new FormData();

    if (selectFile) {
      files.forEach((element) => {
        formData.append("file", element);
      });
    }

    try {
      const filesResponse = await api.post<IFile[]>(
        "/devolution/upload",
        formData
      );
      const response = await api.post("/devolution", {
        devolution,
        files: filesResponse.data.map((item) => ({
          id: item.id,
          name: item.name,
          size: item.size,
          url: item.url,
          key: item.key,
          mimetype: item.mimetype,
        })),
        client: {
          cod: clietStorage.cod,
        },
      });

      if (!admin) {
        await api.put(`/devolution/notification/on/${response.data.id}`);
      }

      setLoading(false);

      addToast("Devulução criada com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/devolucoes");
    } catch (error) {
      setLoading(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function hadleSubmitAdmin(devolution: IDevolution) {
    setLoading(true);
    const formData = new FormData();

    if (selectFile) {
      files.forEach((element) => {
        formData.append("file", element);
      });
    }

    if (provider && files.length < 1) {
      setLoading(false);
      return addToast("É obrigado possuir arquivo anexados", {
        appearance: "warning",
        autoDismiss: true,
      });
    }

    try {
      const filesResponse = await api.post<IFile[]>(
        "/devolution/upload",
        formData
      );
      await api.post("/devolution", {
        devolution,
        files: filesResponse.data.map((item) => ({
          id: item.id,
          name: item.name,
          size: item.size,
          url: item.url,
          key: item.key,
          mimetype: item.mimetype,
        })),
        client: {
          cod: devolution.client?.cod,
        },
      });

      setLoading(false);

      addToast("Devulução criada com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });

      admin
        ? history.push("/admin/devolucoes")
        : history.push("/fornecedor/devolucoes");
    } catch (err) {
      const error = err as any;
      setLoading(false);

      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleClearFilter() {
    setFilters({});
    formikFilter.setFieldValue("cod", "");
    formikFilter.setFieldValue("cnpj", "");
    formikFilter.setFieldValue("company_name", "");
  }

  function dateInvoicevalidate() {
    const days = admin ? 99999 : provider ? 120 : 120;
    let now: Date = new Date();

    now.setDate(now.getDate() - days);
    return now;
  }

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setRequestPagination({
      ...requestPagination,
      pageRequest: 0,
    });
    setFilterView(false);
  }

  function hadleNextPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page + 1,
    });
  }

  function hadlePreviousPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page - 1,
    });
  }

  function handleSelectClient(
    cod: number | undefined,
    company_name: string | undefined,
    email: string | undefined,
    phone: string | undefined
  ) {
    formikCreateDevolution.setFieldValue("client.cod", cod);
    formikCreateDevolution.setFieldValue("client.company_name", company_name);

    formikCreateDevolution.setFieldValue("requesterName", company_name);
    formikCreateDevolution.setFieldValue("phone", phone);
    formikCreateDevolution.setFieldValue("email", email);
    setModalVisible(false);
  }

  async function handleGetInvoiceData(invoice: string) {
    setIsValidNumberInvoice(false);

    if (!invoice)
      return addToast("E necessário informar a seu número de nota fiscal", {
        appearance: "warning",
        autoDismiss: true,
      });

    if (isNaN(Number(invoice)))
      return addToast("Número de nota fiscal invalido", {
        appearance: "warning",
        autoDismiss: true,
      });

    try {
      setLoadingScreen(true);

      const orderResponse = await api.get<Order>(
        `/orderSankhya/invoice/show/${invoice}`
      );

      if (
        orderResponse.data.devolutions &&
        orderResponse.data.devolutions?.length > 0
      ) {
        setDevolutionExistsToInvoice(
          orderResponse.data.devolutions?.map((row) => +row.id)
        );
      } else {
        setDevolutionExistsToInvoice([]);
      }

      setOrder(orderResponse.data);
      setLoadingScreen(false);
      setIsConfirmNumberInvoice(true);
    } catch (err) {
      const error = err as AxiosError;

      setLoadingScreen(false);

      if (error.response?.data?.message) {
        return addToast(error.response?.data?.message, {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (!error.response || error.response.status >= 500) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    }
  }

  function handleSetOrderInDevolution() {
    const { clients_pj, brands, billingDate, documentNumber } = order;

    formikCreateDevolution.resetForm();

    formikCreateDevolution.setFieldValue("numberInvoice", documentNumber);
    formikCreateDevolution.setFieldValue("client.cod", clients_pj?.cod);
    formikCreateDevolution.setFieldValue(
      "client.company_name",
      clients_pj?.company_name
    );
    formikCreateDevolution.setFieldValue(
      "requesterName",
      clients_pj?.company_name
    );
    formikCreateDevolution.setFieldValue("phone", clients_pj?.phone);
    formikCreateDevolution.setFieldValue("email", clients_pj?.email);
    formikCreateDevolution.setFieldValue("brand", brands?.cod?.toString());
    formikCreateDevolution.setFieldValue(
      "dateInvoice",
      moment(billingDate).format("YYYY-MM-DD")
    );

    setIsValidNumberInvoice(true);
    setIsConfirmNumberInvoice(false);
  }

  const formikCreateDevolution = useFormik({
    validateOnChange: false,
    initialValues: {
      //Cliente
      client: {
        cod: undefined,
        company_name: undefined,
      },

      visible: undefined,
      refusal: undefined,

      //Dados do solicitante
      requesterName: clietStorage?.company_name || "-", //nome do solicitante
      phone: clietStorage?.phone || "-", //telefone
      email: clietStorage?.email || "-",

      //Dados da solicitação
      numberInvoice: "", // numero nota fiscal
      situationRequest: provider ? "total" : undefined, //Situação da solicitação
      products: "",

      requestType: "devolução",

      //devolução
      reason: "-", //Motivo
      dateInvoice: "", //Data da nota fiscal
      brand: "", //marca

      //Dados bancários
      // bank: "", //banco
      // agency: "", //banco
      // account: "", //banco
      // typeAccountBank: "", //banco

      //Infprmações Complementares
      complementaryInformation: "",
    },

    validationSchema: Yup.object({
      client: Yup.object().shape({
        cod: Yup.string().test(
          "is_Admin",
          "Cliente é obrigatório.",
          function (value) {
            let error = true;

            if (Boolean(admin) && value === undefined) error = false;

            return error;
          }
        ),
      }),

      visible: Yup.boolean().test(
        "is_Admin",
        "Solitação visível para cliente é obrigatório.",
        function (value) {
          let error = true;
          if (Boolean(admin) && value === undefined) error = false;

          return error;
        }
      ),

      refusal: Yup.boolean().test(
        "is_Admin",
        "Tipo de solicitação é obrigatório.",
        function (value) {
          let error = true;
          if (Boolean(admin) && value === undefined) error = false;

          return error;
        }
      ),

      situationRequest: Yup.string().required(
        "Situação da solicitação é obrigatório"
      ),

      requesterName: Yup.string().required("Nome solicitante é obrigatório"),
      phone: Yup.string()
        // .matches(phoneRegExp, "Formato do telefone invalido, formato correto (DD) 00000-0000")
        .required("Telefone solicitante é obrigatório"),
      email: Yup.string()
        // .email("informe um e-mail válido")
        .required("E-mail solicitante é obrigatório"),

      numberInvoice: Yup.number()
        .typeError("Apenas numeros")
        .required("Nota fiscal nº é obrigatório"),

      products: Yup.string().when("situationRequest", {
        is: "parcial",
        then: Yup.string().required("Produtos é obrigatório"),
      }),

      //Devolução & Indenização
      reason: Yup.string().required("Motivo solicitante é obrigatório"),
      brand: Yup.string().required("Marca é obrigatório"),

      // Devolução
      dateInvoice: Yup.date()
        .test(
          "is_Admin",
          "O prazo máximo para solicitação de devolução é de 14 dias.",
          function (value) {
            let error = true;
            //@ts-ignore
            if (value === undefined || value === "" || isNaN(value))
              return error;

            if (!admin) {
              //@ts-ignore
              if (new Date(value) < dateInvoicevalidate()) {
                error = false;
              }
            }

            return error;
          }
        )
        // .min(dateInvoicevalidate(), `Data de emissão da NF ao consumidor invalida`)
        .required("Data nota fiscal é obrigatório")
        .max(new Date(), `Data invalida.`),

      // bank: Yup.string().required("Banco é obrigatório"),
      // agency: Yup.string().required("Agência é obrigatório"),
      // account: Yup.string().required("Conta é obrigatório"),
      // typeAccountBank: Yup.string().required("Tipo da conta é obrigatório"),

      complementaryInformation: Yup.string().required(
        "Informações complementares é obrigatório"
      ),
    }),

    onSubmit: (values) => {
      admin || provider ? hadleSubmitAdmin(values) : hadleSubmitClient(values);
    },
  });

  const formikFilter = useFormik({
    initialValues: {
      cod: filters.cod,
      company_name: filters.company_name,
      cnpj: filters.cnpj,
    },

    validationSchema: Yup.object({}),

    onSubmit: (data) =>
      hadleFilter({
        cnpj: data.cnpj?.trim()?.toUpperCase(),
        cod: data.cod?.trim(),
        company_name: data.company_name?.trim()?.toUpperCase(),
      }),
  });

  useOutsideAlerter(outSideRefFilter, () => {
    if (filterView) setFilterView(false);
  });

  return (
    <>
      {loadingScreen && (
        <ScreenLoading
          style={{
            height: `100vh`,
            width: `100vw`,
            position: `fixed`,
            zIndex: 1,
            background: `white`,
            opacity: 0.8,
          }}
        >
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
      <Container>
        <header>
          <button
            type="button"
            onClick={() => {
              history.goBack();
            }}
            className="arrowBack"
          >
            <FaArrowLeft size={18} />
          </button>

          <h2>Nova solitação de devolução</h2>
        </header>
        <ContainerForm>
          <Form
            onSubmit={
              loading
                ? (e) => {
                    e.preventDefault();
                  }
                : formikCreateDevolution.handleSubmit
            }
          >
            <h3>Dados da solitação</h3>
            <div className="field">
              <label htmlFor="numberInvoice">Nota fiscal nº</label>
              <input
                id="numberInvoice"
                {...formikCreateDevolution.getFieldProps("numberInvoice")}
                onBlur={(e) => handleGetInvoiceData(e.target.value)}
              />
              {formikCreateDevolution.touched.numberInvoice &&
              formikCreateDevolution.errors.numberInvoice ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formikCreateDevolution.errors.numberInvoice} </span>
                </Error>
              ) : null}
            </div>

            {isValidNumberInvoice && (
              <>
                <div className="field-group">
                  <div className="field">
                    <label htmlFor="name">Marca</label>
                    <select
                      disabled
                      style={{ borderStyle: `solid` }}
                      {...formikCreateDevolution.getFieldProps("brand")}
                    >
                      <option value="">Selecionar marca</option>
                      {brands.map((item) => (
                        <option key={item.cod} value={item.cod}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {formikCreateDevolution.touched.brand &&
                    formikCreateDevolution.errors.brand ? (
                      <Error>
                        <FiAlertCircle color="#f00" size={16} />
                        <span> {formikCreateDevolution.errors.brand} </span>
                      </Error>
                    ) : null}
                  </div>

                  <div className="field">
                    <label htmlFor="dateInvoice">Data nota fiscal</label>
                    <input
                      disabled
                      style={{ borderStyle: `solid` }}
                      type="date"
                      {...formikCreateDevolution.getFieldProps("dateInvoice")}
                    />
                    {formikCreateDevolution.touched.dateInvoice &&
                    formikCreateDevolution.errors.dateInvoice ? (
                      <Error>
                        <FiAlertCircle color="#f00" size={16} />
                        <span>
                          {" "}
                          {formikCreateDevolution.errors.dateInvoice}{" "}
                        </span>
                      </Error>
                    ) : null}
                  </div>
                </div>

                {!provider && (
                  <>
                    <div className="field">
                      <label htmlFor="name">Situação da solicitação</label>
                      <ContainerCheck>
                        <button
                          type="button"
                          className={
                            formikCreateDevolution.values.situationRequest ===
                            "total"
                              ? "check"
                              : ""
                          }
                          onClick={() =>
                            formikCreateDevolution.setFieldValue(
                              "situationRequest",
                              "total"
                            )
                          }
                        >
                          Total
                        </button>

                        <button
                          type="button"
                          onClick={() =>
                            formikCreateDevolution.setFieldValue(
                              "situationRequest",
                              "parcial"
                            )
                          }
                          className={
                            formikCreateDevolution.values.situationRequest ===
                            "parcial"
                              ? "check"
                              : ""
                          }
                        >
                          Parcial
                        </button>
                      </ContainerCheck>
                      {formikCreateDevolution.touched.situationRequest &&
                      formikCreateDevolution.errors.situationRequest ? (
                        <Error>
                          <FiAlertCircle color="#f00" size={16} />
                          <span>
                            {formikCreateDevolution.errors.situationRequest}
                          </span>
                        </Error>
                      ) : null}
                    </div>
                    {formikCreateDevolution.values.situationRequest ===
                    "parcial" ? (
                      <div className="field">
                        <label htmlFor="products">Produtos</label>
                        <input
                          id="products"
                          {...formikCreateDevolution.getFieldProps("products")}
                        />
                        {formikCreateDevolution.touched.products &&
                        formikCreateDevolution.errors.products ? (
                          <Error>
                            <FiAlertCircle color="#f00" size={16} />
                            <span>
                              {formikCreateDevolution.errors.products}
                            </span>
                          </Error>
                        ) : null}
                      </div>
                    ) : null}
                  </>
                )}

                <h3>Informações complementares</h3>
                <div className="field">
                  <textarea
                    id="complementaryInformation"
                    {...formikCreateDevolution.getFieldProps(
                      "complementaryInformation"
                    )}
                  ></textarea>
                  {formikCreateDevolution.touched.complementaryInformation &&
                  formikCreateDevolution.errors.complementaryInformation ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span>
                        {formikCreateDevolution.errors.complementaryInformation}
                      </span>
                    </Error>
                  ) : null}
                </div>

                {admin && (
                  <>
                    <h3>Cliente</h3>
                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="cod">Código Cliente</label>
                        <input
                          id="cod"
                          type="number"
                          {...formikCreateDevolution.getFieldProps(
                            "client.cod"
                          )}
                          style={{ borderStyle: "dashed" }}
                          readOnly={true}
                        />
                      </div>

                      <div className="field">
                        <label htmlFor="company_name">Razão Social</label>
                        <input
                          style={{ borderStyle: "dashed" }}
                          id="company_name"
                          type="text"
                          {...formikCreateDevolution.getFieldProps(
                            "client.company_name"
                          )}
                          readOnly={true}
                        />
                      </div>
                    </div>

                    <div className="field">
                      <div className="selectClient">
                        <button
                          type="button"
                          onClick={() => setModalVisible(!modalVisible)}
                        >
                          Selecionar cliente
                        </button>
                      </div>

                      {formikCreateDevolution.touched.client?.cod &&
                      formikCreateDevolution.errors.client?.cod ? (
                        <Error>
                          <FiAlertCircle color="#f00" size={16} />
                          <span>
                            {formikCreateDevolution.errors.client?.cod}
                          </span>
                        </Error>
                      ) : null}
                    </div>

                    <h3>Solitação visível para cliente</h3>
                    <div className="field">
                      <ContainerCheck>
                        <button
                          type="button"
                          className={
                            formikCreateDevolution.values.visible === true
                              ? "check"
                              : ""
                          }
                          onClick={() =>
                            formikCreateDevolution.setFieldValue(
                              "visible",
                              true
                            )
                          }
                        >
                          Sim
                        </button>

                        <button
                          type="button"
                          onClick={() =>
                            formikCreateDevolution.setFieldValue(
                              "visible",
                              false
                            )
                          }
                          className={
                            formikCreateDevolution.values.visible === false
                              ? "check"
                              : ""
                          }
                        >
                          Não
                        </button>
                      </ContainerCheck>
                      {formikCreateDevolution.touched.visible &&
                      formikCreateDevolution.errors.visible ? (
                        <Error>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formikCreateDevolution.errors.visible} </span>
                        </Error>
                      ) : null}
                    </div>

                    <h3>Tipo de solicitação</h3>
                    <div className="field">
                      <ContainerCheck>
                        <button
                          type="button"
                          className={
                            formikCreateDevolution.values.refusal === true
                              ? "check"
                              : ""
                          }
                          onClick={() =>
                            formikCreateDevolution.setFieldValue(
                              "refusal",
                              true
                            )
                          }
                        >
                          RECUSA
                        </button>

                        <button
                          type="button"
                          onClick={() =>
                            formikCreateDevolution.setFieldValue(
                              "refusal",
                              false
                            )
                          }
                          className={
                            formikCreateDevolution.values.refusal === false
                              ? "check"
                              : ""
                          }
                        >
                          DEVOLUÇÃO
                        </button>
                      </ContainerCheck>
                      {formikCreateDevolution.touched.refusal &&
                      formikCreateDevolution.errors.refusal ? (
                        <Error>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formikCreateDevolution.errors.refusal} </span>
                        </Error>
                      ) : null}
                    </div>

                    {modalVisible && (
                      <Modal
                        title="Selecione o cliente"
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                      >
                        <ModalBody>
                          <Filter>
                            <button
                              onClick={() => {
                                setFilterView(!filterView);
                              }}
                              type="button"
                            >
                              <FiSearch />
                            </button>
                            <input
                              onClick={() => {
                                setFilterView(!filterView);
                              }}
                              type="text"
                              name="filter"
                              id="filter"
                              readOnly={true}
                            />
                            <FilterSelects>
                              <ul>
                                {filters.cod && (
                                  <li>
                                    <span>COD: {filters.cod} </span>
                                  </li>
                                )}
                                {filters.company_name && (
                                  <li>
                                    <span>
                                      Razão social: {filters.company_name}
                                    </span>
                                  </li>
                                )}
                                {filters.cnpj && (
                                  <li>
                                    <span>CNPJ: {filters.cnpj}</span>
                                  </li>
                                )}
                              </ul>
                            </FilterSelects>

                            {filterView && (
                              <FilterCheck ref={outSideRefFilter}>
                                <MainFilter>
                                  <Form onSubmit={formikFilter.handleSubmit}>
                                    <div className="field-group">
                                      <div className="field">
                                        <label htmlFor="cod">COD</label>
                                        <input
                                          id="cod"
                                          {...formikFilter.getFieldProps("cod")}
                                        />
                                      </div>
                                      <div className="field">
                                        <label htmlFor="company_name">
                                          Razão social
                                        </label>
                                        <input
                                          id="company_name"
                                          {...formikFilter.getFieldProps(
                                            "company_name"
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <div className="field">
                                      <label htmlFor="cnpj">CNPJ</label>
                                      <input
                                        id="cnpj"
                                        {...formikFilter.getFieldProps("cnpj")}
                                      />
                                    </div>

                                    <footer>
                                      <button
                                        className="clearFilter"
                                        type="button"
                                        onClick={() => {
                                          handleClearFilter();
                                        }}
                                      >
                                        LIMPAR FILTRO
                                      </button>
                                      <button type="submit">BUSCAR</button>
                                    </footer>
                                  </Form>
                                </MainFilter>
                              </FilterCheck>
                            )}
                          </Filter>
                          <ContainerList>
                            <TableContainer>
                              <table>
                                <thead>
                                  <tr>
                                    <th>COD</th>
                                    <th>RAZAO SOCIAL</th>
                                    <th>CNPJ</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {clints.map((client: IClient) => (
                                    <tr
                                      key={client.id}
                                      onClick={() =>
                                        handleSelectClient(
                                          client.cod,
                                          client.company_name,
                                          client.email,
                                          client.phone
                                        )
                                      }
                                    >
                                      <td> {client.cod} </td>
                                      <td> {client.company_name} </td>
                                      <td> {client.cnpj} </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </TableContainer>
                            <FooterContainer>
                              <span>
                                Mostrando {clints.length} de {pagination.total}{" "}
                                resultados
                              </span>

                              <div>
                                <button
                                  className={
                                    pagination.page <= 0 ? "buttonNull" : ""
                                  }
                                  type="button"
                                  onClick={
                                    pagination.page <= 0
                                      ? () => {}
                                      : hadlePreviousPage
                                  }
                                >
                                  ANTERIOR
                                </button>
                                <p style={{ marginRight: 8 }}>
                                  {pagination.page + 1}
                                </p>
                                <button
                                  className={
                                    pagination.page >= pagination.pages
                                      ? "buttonNull"
                                      : ""
                                  }
                                  type="button"
                                  onClick={
                                    pagination.page >= pagination.pages
                                      ? () => {}
                                      : hadleNextPage
                                  }
                                >
                                  PROXIMO
                                </button>
                              </div>
                            </FooterContainer>
                          </ContainerList>
                        </ModalBody>
                      </Modal>
                    )}
                  </>
                )}

                <h3>Arquivos</h3>
                <ContainerDropZone>
                  <Dropzone
                    accept={[
                      "image/*",
                      "application/pdf",
                      "application/xml",
                      "text/xml",
                    ]}
                    onFileUploaded={setSelectFile}
                  />
                  <ContainerPreviews
                    style={{
                      overflowY: "auto",
                      maxHeight: "40vh",
                    }}
                  >
                    {files.map((file) => (
                      <li key={`${generateHash()}-${file.name}`}>
                        <img
                          alt={file.name}
                          src={
                            file.type === "application/pdf"
                              ? iconPdf
                              : file.type === "text/xml" ||
                                file.type === "application/xml"
                              ? iconXml
                              : URL.createObjectURL(file)
                          }
                        />
                        <div className="fileInfo">
                          <div>
                            <strong>{file.name}</strong>
                            <span>{filesize(file.size)}</span>
                          </div>

                          <button
                            type="button"
                            onClick={() => {
                              hadleDeleteFile(file.name);
                            }}
                          >
                            <IoMdTrash size={30} />
                          </button>
                        </div>
                      </li>
                    ))}
                  </ContainerPreviews>
                </ContainerDropZone>

                <div className="containerButton">
                  <ButtunSubmit type="submit">
                    {loading ? (
                      <Loading
                        size={22}
                        borderSize={4}
                        colorLoading="rgba(255,255,255)"
                        borderColor="rgba(255,255,255, 0.3)"
                      />
                    ) : (
                      "Enviar solicitação"
                    )}
                  </ButtunSubmit>
                </div>
              </>
            )}
          </Form>
        </ContainerForm>
      </Container>

      <ModalConfirm
        modalVisible={isConfirmNumberInvoice}
        setModalVisible={setIsConfirmNumberInvoice}
        headerOff
        maxHeight={500}
        maxWidth={600}
        scrollOff
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <BsFileEarmarkCheck size={100} opacity={0.2} />
            </nav>
            <span>Confirmação de nota fiscal</span>
          </div>

          {devolutionExistsToInvoice.length > 0 && (
            <div style={{ padding: "0rem 5rem 1rem 5rem" }}>
              <span style={{ color: "#F00", fontWeight: "bold" }}>
                Não é possível realizar criação, já existe um protocolo(s) (
                {devolutionExistsToInvoice.map((item) => (
                  <a
                    href={`${FRONT_LINK}/${
                      admin ? "admin/" : provider ? "fornecedor/" : ""
                    }devolucao/${item}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#000", fontWeight: "lighter" }}
                  >
                    #{item}
                  </a>
                ))}
                ) com mesma numero Nota fiscal.
              </span>
            </div>
          )}

          <div
            style={{ color: "#555", fontWeight: "lighter", fontSize: "1rem" }}
          >
            <p>#{order.cod}</p>
            <p>
              Valor:{" "}
              {Number(order.noteValue).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </p>
            <p>Marca: {order.brands?.name}</p>
            <p>
              Representante:{" "}
              {order.seller
                ? `${order.seller.cod} ${order.seller.abbreviation}`
                : "-"}
            </p>
            <p>
              Data entrada: {moment(order.deliveryDate).format("DD/MM/YYYY")}
            </p>
            <p>
              {`Data faturamento: ${moment(order.billingDate).format(
                "DD/MM/YYYY"
              )}`}
            </p>
          </div>
          {new Date(order.billingDate) < dateInvoicevalidate() && (
            <div>
              <span style={{ color: "#F00" }}>
                *Prazo máximo para solicitação de devolução é de 14 dias.
              </span>
            </div>
          )}

          <div className="buttonsPopUp" style={{ padding: "15px 10px" }}>
            <button
              type="button"
              onClick={() => {
                setIsConfirmNumberInvoice(false);
                setOrder({} as Order);
              }}
            >
              Cancelar
            </button>
            <button
              type="button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleSetOrderInDevolution}
              disabled={
                new Date(order.billingDate) < dateInvoicevalidate()
                  ? true
                  : admin
                  ? false
                  : devolutionExistsToInvoice.length > 0
              }
            >
              Confirmo
            </button>
          </div>
        </PopUpConfirmation>
      </ModalConfirm>
    </>
  );
};

export default CreateDecolucao;
