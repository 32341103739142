import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { useFormik } from "formik";
import { FieldProps } from "../../../@types/panelTypes";

import { IoReload } from "react-icons/io5";
import { RiFileExcel2Fill } from "react-icons/ri";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { InputCheckboxV2 } from "../../../components/Form/InputCheckboxV2";
import { Select } from "../../../components/Form/Select";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { Pagination } from "../../../components/panel/table/Pagination";
import { TableContent } from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import { Popover } from "../../../components/Popover";
import {
  getSellers,
  Seller,
  SellerFilters,
  useSellers,
} from "../../../hook/queries/useSeller";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import { generateXlsx } from "../../../utils/generateXlsx";
import { objectToForEach } from "../../../utils/objectToForEach";
import { setFormikValues } from "../../../utils/setFormikValues";

const columns: FieldProps[] = [
  {
    name: "cod",
    field: "CÓDIGO",
    viewList: true,
  },
  {
    name: "fullName",
    field: "NOME COMPLETO",
    viewList: true,
  },
  {
    name: "abbreviation",
    field: "ABREVIAÇÃO",
    viewList: true,
  },
  {
    name: "is_activeNormalized",
    field: "SITUAÇÃO",
    viewList: true,
  },
  {
    name: "nextdata_activeNormalized",
    field: "SITUAÇÃO NEXTDATA",
    viewList: true,
  },
  {
    name: "email",
    field: "EMAIL",
    viewList: true,
  },
  {
    name: "active",
    field: "SITUAÇÃO",
    viewList: false,
  },

  {
    name: "groups",
    field: "GRUPO",
    viewList: false,
  },
  {
    name: "brands",
    field: "MARCAS",
    viewList: false,
  },
  {
    name: "nextdata_active",
    field: "Situação NEXTDATA",
    viewList: false,
  },
  {
    name: "manager",
    field: "Gerente",
    viewList: false,
  },
  {
    name: "supervisor",
    field: "Supervisor",
    viewList: false,
  },
  {
    name: "uf",
    field: "UF",
    viewList: false,
  },
  {
    name: "city",
    field: "Cidade",
    viewList: false,
  },
];

type Props = {
  admin?: boolean;
};

const PanelServiceInvoice: React.FC<Props> = ({ admin }) => {
  const history = useHistory();
  const query = useQueryParams();

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });

  const [viewFilter, setViewFilter] = useState(false);

  const formikFilter = useFormik({
    initialValues: {
      cod: "",
      abbreviation: "",
      fullName: "",
      email: "",
      active: "",
      brands: [],
      groups: [],
    },
    onSubmit: (data) => {
      handleFilter(data as any);
    },
  });

  const [filters, setFilters] = useState<SellerFilters>(() => {
    let filtersValueQueryParams = { active: 1 };
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      const valueNormalized = ["groups", "brands"].includes(key)
        ? value.split(",")
        : value;

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: valueNormalized,
        };
      }
    });

    return filtersValueQueryParams;
  });

  const { data, isLoading, isFetching, refetch } = useSellers(
    page,
    filters,
    15
  );

  async function handleExport(type: 1 | 2 | 3) {
    const getData = await getSellers(1, filters, 99999);

    switch (type) {
      case 1:
        generateXlsx({
          data: getData.sellers.map((seller) => ({
            CÓDIGO: seller?.cod ?? "-",
            "NOME COMPLETO": seller?.fullName ?? "-",
            ABREVIAÇÃO: seller?.abbreviation ?? "-",
            SITUAÇÃO: seller?.is_activeNormalized ?? "-",
            "TIPO VENDEDOR": seller?.typeSeller ?? "-",
            CNPJ: seller.cnpj,
            MARCAS: seller.brands.map((brand) => brand.name).join(","),
            EMAIL: seller?.email ?? "-",
            TELEFONE: seller?.phone ?? "-",

            "É GERENTE": seller?.is_manager ? "SIM" : "NÃO",
            "É SUPERVISOR": seller?.is_supervisor ? "SIM" : "NÃO",

            "CÓD. GERENTE": seller?.cod_manager ?? "-",
            "CÓD. SUPERVISOR": seller?.cod_supervisor ?? "-",

            CEP: seller?.addrres?.zip_code,
            UF: seller?.addrres?.uf,
            CIDADE: seller?.addrres?.city,
            BAIRRO: seller?.addrres?.neighborhood,
            LOGRADOURO: seller?.addrres?.street,

            "SITUAÇÃO NEXTDATA": seller?.nextdata_activeNormalized ?? "-",
            "LOGIN NEXTDATA": seller?.nextdata_login ?? "-",
            "SENHA NEXTDATA": seller?.nextdata_password ?? "-",
          })),
          filename: "Representantes",
        });
        break;

      case 2:
        let dataGroupToSeller = [];

        for (const seller of getData.sellers) {
          for (const group of seller.groupsToSeller) {
            dataGroupToSeller.push({
              CÓDIGO: seller?.cod ?? "-",
              "NOME COMPLETO": seller?.fullName ?? "-",
              ABREVIAÇÃO: seller?.abbreviation ?? "-",
              "GRUPO DE PRODUTO": `${group.group.cod} -  ${group.group.descrpition}`,
              SITUAÇÃO: seller?.is_activeNormalized ?? "-",
              "TIPO VENDEDOR": seller?.typeSeller ?? "-",
              CNPJ: seller.cnpj,
              MARCAS: seller.brands.map((brand) => brand.name).join(","),
              EMAIL: seller?.email ?? "-",
              TELEFONE: seller?.phone ?? "-",

              "É GERENTE": seller?.is_manager ? "SIM" : "NÃO",
              "É SUPERVISOR": seller?.is_supervisor ? "SIM" : "NÃO",

              "CÓD. GERENTE": seller?.cod_manager ?? "-",
              "CÓD. SUPERVISOR": seller?.cod_supervisor ?? "-",

              CEP: seller?.addrres?.zip_code,
              UF: seller?.addrres?.uf,
              CIDADE: seller?.addrres?.city,
              BAIRRO: seller?.addrres?.neighborhood,
              LOGRADOURO: seller?.addrres?.street,
            });
          }
        }

        generateXlsx({
          data: dataGroupToSeller,
          filename: "Representantes & Grupos de produto",
        });

        break;

      case 3:
        const VALUE_TABLET = 45;
        const listSellerRemoveInternalSellers = getData.sellers
          .filter((f) => f.is_manager === false)
          .filter((f) => f.is_supervisor === false)
          .filter((f) => f.is_active === true)
          .filter((f) => f.nextdata_active === true)
          .filter(
            (f) =>
              ![
                9999, 8001, 3049, 3048, 3047, 3022, 2945, 2929, 2928, 2927,
                2875, 2818, 2637, 2626, 2343, 2135, 1894, 1, 701, 1842, 2083,
                2588, 2999, 2998, 8500, 1130,
              ].includes(f.cod)
          )
          .map((seller) => {
            const equip = getData.sellers.filter(
              (f) =>
                f.cnpj === seller.cnpj &&
                seller.brands
                  .map((b) => b.cod)
                  .some((value) => f.brands.map((b) => b.cod).includes(value))
            ).length;

            const qtd =
              seller?.typeSeller === "Preposto" ? 0 : equip === 0 ? 1 : equip;
            const value = VALUE_TABLET * qtd;

            return {
              CÓDIGO: seller?.cod ?? "-",
              "NOME COMPLETO": seller?.fullName ?? "-",
              ABREVIAÇÃO: seller?.abbreviation ?? "-",
              SITUAÇÃO: seller?.is_activeNormalized ?? "-",
              VALOR: value,
              // .toLocaleString("pt-br", {
              //   style: "currency",
              //   currency: "BRL",
              // })
              "TIPO VENDEDOR": seller?.typeSeller ?? "-",
              CNPJ: seller.cnpj,
              MARCAS: seller.brands.map((brand) => brand.name).join(","),
              EMAIL: seller?.email ?? "-",
              TELEFONE: seller?.phone ?? "-",
            };
          });

        generateXlsx({
          data: listSellerRemoveInternalSellers.sort((a, b) => {
            return Number(b.CNPJ ?? 0) - Number(a.CNPJ ?? 0);
          }),
          filename: "Representantes",
        });
        break;
    }
  }

  function handleFilter(filter: SellerFilters) {
    setFilters({
      ...filter,
      brands: !!filter.brands?.length ? filter.brands : undefined,
      groups: !!filter.groups?.length
        ? filter?.groups?.map((f) => Number(f))
        : undefined,
    });
    setPage(1);
    setViewFilter(false);
  }

  function normalizedFilters(value: any, label: string) {
    let normalized = value;

    switch (label) {
      case "nextdata_active":
        if (Number(value) === 1) {
          normalized = "ATIVO";
        } else {
          normalized = "INATIVO";
        }

        break;
      case "active":
        if (Number(value) === 1) {
          normalized = "ATIVO";
        } else {
          normalized = "INATIVO";
        }

        break;
      case "brands":
        normalized = formikFilter?.values?.brands?.join(", ");

        break;
      case "groups":
        normalized = formikFilter?.values?.groups
          ?.map(
            (group) =>
              data?.filters?.group.find((f) => Number(f?.id) === Number(group))
                ?.name
          )
          ?.join(", ");

        break;
    }

    return normalized;
  }

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      const valueNormalized = ["groups", "brands"].includes(key)
        ? value.split(",")
        : value;

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: valueNormalized,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de representante" />

      <PanelFilter
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        onSubmit={formikFilter.handleSubmit}
        onResetForm={formikFilter.resetForm}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        normalizedFilters={normalizedFilters}
      >
        <GroupInput>
          <Input label="Código" {...formikFilter.getFieldProps("cod")} />
          <Input
            label="Abreviação"
            {...formikFilter.getFieldProps("abbreviation")}
          />
          <Input
            label="Nome completo"
            {...formikFilter.getFieldProps("fullName")}
          />
          <Input label="Email" {...formikFilter.getFieldProps("email")} />
        </GroupInput>

        <GroupInput>
          <Select
            label="Situação"
            data={[
              {
                name: "Ativo",
                value: "1",
              },
              {
                name: "Inativo",
                value: "0",
              },
            ]}
            {...formikFilter.getFieldProps("active")}
          />
          <Select
            label="Situação NEXTDATA"
            data={[
              {
                name: "Ativo",
                value: "1",
              },
              {
                name: "Inativo",
                value: "0",
              },
            ]}
            {...formikFilter.getFieldProps("nextdata_active")}
          />
        </GroupInput>

        <GroupInput>
          <Select
            label="Gerente"
            {...formikFilter.getFieldProps("manager")}
            data={
              data?.filters?.manager?.map((error) => ({
                value: String(error.id),
                name: `${error.id} - ${error.name}`,
              })) ?? []
            }
          />
          <Select
            label="Supervisor"
            {...formikFilter.getFieldProps("supervisor")}
            data={
              data?.filters?.supervisor?.map((error) => ({
                value: String(error.id),
                name: `${error.id} - ${error.name}`,
              })) ?? []
            }
          />
        </GroupInput>

        <GroupInput>
          <Select
            label="UF"
            {...formikFilter.getFieldProps("uf")}
            data={
              data?.filters?.uf?.map((error) => ({
                value: String(error.id),
                name: error.id,
              })) ?? []
            }
          />
          <Select
            label="Cidade"
            {...formikFilter.getFieldProps("city")}
            data={
              data?.filters?.city?.map((error) => ({
                value: String(error.id),
                name: error.id,
              })) ?? []
            }
          />
        </GroupInput>

        <GroupInput>
          <InputCheckboxV2
            height="120px"
            name="brands"
            label="Marcas"
            checks={formikFilter.values.brands}
            onChange={(value) => {
              formikFilter.setFieldValue("brands", value);
            }}
            data={
              data?.filters?.brand?.map((error) => ({
                value: String(error.id),
                description: error.name,
              })) ?? []
            }
          />
          <InputCheckboxV2
            height="120px"
            name="groups"
            label="Grupos"
            checks={formikFilter.values.groups}
            onChange={(value) => {
              formikFilter.setFieldValue("groups", value);
            }}
            data={
              data?.filters?.group?.map((error) => ({
                value: String(error.id),
                description: error.name,
              })) ?? []
            }
          />
        </GroupInput>
      </PanelFilter>

      <TableHeader title="REPRESENTANTES" isFetching={isFetching && !isLoading}>
        <Popover
          isVisible
          Button={
            <button style={{ background: "#1d6f42" }}>
              <RiFileExcel2Fill size={19} />
              <span>Exportar</span>
            </button>
          }
        >
          <li
            onClick={() => {
              handleExport(1);
            }}
          >
            <span>Exportar representante</span>
          </li>
          <li
            onClick={() => {
              handleExport(2);
            }}
          >
            <span>Exportar representante & grupos de produto</span>
          </li>
          <li
            onClick={() => {
              handleExport(3);
            }}
          >
            <span>Relatório Nextdata</span>
          </li>
        </Popover>

        <button onClick={() => refetch()}>
          <IoReload size={19} />
          <span>RECARREGAR</span>
        </button>
      </TableHeader>

      <TableContent<Seller>
        data={data?.sellers}
        columns={columns.filter((f) => f.viewList)}
        isLoading={isLoading}
        onClickRow={(e) => {
          if (admin) {
            history.push(`/admin/representante/${e.id}`);
          } else {
            history.push(`/representante/representante/${e.id}`);
          }
        }}
      />

      <Pagination
        totalCountOfRegisters={data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
        registersPerPage={15}
      />
    </PanelLayout>
  );
};

export default PanelServiceInvoice;
