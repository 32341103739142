import styled from "styled-components";

export const Container = styled.button`
  padding: 0 1rem;
  margin: auto;
  margin-top: 1.1rem;
  height: 2.5rem;

  font-weight: 700;
  font-size: 16px;
  color: #fff;

  background: #1995ef;
  border-radius: 8px;

  &:hover {
    opacity: 0.85;
  }
`;
