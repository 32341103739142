import { useQuery, UseQueryResult } from "react-query";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type Provider = {
  id: number;
  cod?: number;
  email: string;
  fullname: string;
};

export type ProviderFilters = {
  isShippingCompany?: number;
};

type GetProvidersResponse = {
  providers: Provider[];
  totalCount: number;
};

interface ProviderApiResponse extends ResponsePanelApiDefault {
  contents: Provider[];
}

export async function getProviders(
  page: number,
  filter?: ProviderFilters,
  registersPerPag?: number
): Promise<GetProvidersResponse> {
  const { data } = await api.get<ProviderApiResponse>(
    "/populate/provider/list",
    {
      params: {
        pageRequest: page - 1,
        limitRequest: registersPerPag,
        ...filter,
      },
    }
  );

  return {
    totalCount: data.total,
    providers: data.contents,
  };
}

export function useProviders(
  page: number,
  filter?: ProviderFilters,
  registersPerPag?: number
): UseQueryResult<GetProvidersResponse> {
  return useQuery(
    ["providers", page, filter],
    () => getProviders(page, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}
